@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap");

.tracker-app {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding: 50px 0px;
  flex-direction: column;
  align-items: center;
  font-family: "Jost", sans-serif;
}

.logo {
  max-width: 306px;
  margin: 0 auto;
  margin-bottom: 60px;
  cursor: pointer;
}

.logo svg {
  width: 100%;
}
.tracker-forms {
  width: 972px;
  min-height: 440px;
  border-radius: 10px;
  box-shadow: 0 0 15px 0 rgba(212, 212, 212, 0.25);
  background-color: #fff;
}

.blue-bg-form {
  height: 100%;
  max-width: 50%;
  width: 50%;
  float: left;
  padding: 32px 59px 30px 62px;
  border-radius: 10px 0px 0px 10px;
  background-color: #1c3e92;
  position: relative;
}
.blue-bg-form h3 {
  margin: 0;
  font-size: 35px;
  font-weight: 400;
  text-align: left;
  color: #fff;
}

.number-search-field {
  margin-top: 25px;
}
.number-search-field .form-label {
  margin: 0 0 6px 0;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  color: #fff;
}
.number-search-field .input-group {
  border: solid 1px #e9e8e8;
  background-color: #fff;
  border-radius: 5px;
}
.number-search-field .form-control {
  height: 50px;
  border-radius: 0px !important;
  border: 0 !important;
  padding-left: 8px;
  box-shadow: none;
  font-size: 14px;
  font-weight: 400;
  text-align: left;

  color: #8f8f8f;
}
.number-search-field .input-group-text {
  padding-left: 14px;
  padding-right: 0;
  border-radius: 0px !important;
  border: 0 !important;
  background: #fff !important;
}
.number-search-field .form-control::placeholder,
.form-field .form-control::placeholder {
  color: #e4e4e4;
}
.or-line {
  position: relative;
  margin-top: 25px;
  margin-bottom: -8px;
}
.or-line:before {
  left: 0;
}
.or-line::after,
.or-line:before {
  width: 162px;
  height: 2px;
  border-radius: 2px;
  background-color: #fff;
  display: block;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.or-line::after {
  right: 0;
}
.or-line span {
  display: block;
  width: 22px;
  height: 23px;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #f68d2c;
  margin: 0 auto;
}

.btn.btn-primary {
  width: 100%;
  height: 55px;
  border: 0;
  outline: none;
  box-shadow: none;
  border-radius: 2px;
}

.blue-bg-form .btn.btn-primary {
  background-color: #f68d2c;
  margin: 29px 0 0;
}
/* 2nd form css  */
.white-bg-form {
  padding: 68px 69px 59px 62px;
  float: left;
  width: 50%;
  position: relative;
}
.white-bg-form .btn.btn-primary {
  background-color: #193e8f;
}
.white-bg-form .btn.btn-primary:hover {
  background-color: #f68d2c;
}

.form-field .form-label {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #8f8f8f;
  top: -13px;
  left: 6px;
  background: #ffffff;
  padding: 0px 5px 0px 5px;
  position: absolute;
  z-index: 1;
}

.form-field {
  border: solid 1px #e9e8e8;
  background-color: #fff;
  border-radius: 5px;
  position: relative;
  margin-bottom: 36px;
}
.form-field .form-control {
  height: 50px;
  border-radius: 0px !important;
  border: 0 !important;
  padding-left: 10px;
  box-shadow: none;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  background: transparent !important;
  color: #8f8f8f;
}
.form-field .input-group-text {
  padding-left: 15px;
  padding-right: 0;
  border-radius: 0px !important;
  border: 0 !important;
  background: transparent !important;
}
.form-field .form-control::placeholder {
  color: #e4e4e4;
}

.voh-order-error {
  color: white;
  background-color: #ff0000;
  font-size: 14px;
  font-weight: 400;
  position: absolute;

  z-index: 11;
  padding: 7px;
  left: 0;
  right: 0;
  text-align: center;
  animation: flash 1s;
  animation-fill-mode: forwards;
}
@keyframes flash {
  0% {
    top: 60%;
    opacity: 0;
  }

  100% {
    top: 0px;
    opacity: 1;
  }
}

/* tracking details css from here  */

.tracking-details {
  width: 1100px;
  height: 603px;
  padding: 44px 173px 25px 61px;
  border-radius: 10px;
  box-shadow: 0 0 15px 0 rgba(212, 212, 212, 0.25);
  background-color: #fff;
}

.order-details {
  width: 440px;
  float: left;
  position: relative;
}

.link-copy {
  padding: 15px 66px 12px 19px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1c3e92;
}

.link-texts {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  text-align: left;
  color: #fff;
  margin-left: 20px;
}
.link-texts a {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #f4ad3d;

  text-decoration: none;
}
.tracking-details .btn-secondary {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: solid 1px #e9e8e8;
  background-color: #d9d9d9;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  color: #000;
  margin: 25px 0px;
}

.main-head {
  margin: 0;
  font-size: 35px;
  font-weight: 500;
  text-align: left;
  color: #131313;
}

.general-text {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #464646;
  margin-top: 4px;
  margin-bottom: 25px;
}

.sub-head {
  margin: 0;
  font-size: 22px;
  font-weight: 500;
  text-align: left;
  color: #131313;
  text-transform: capitalize;
}

.shipping-details {
  width: 210px;
  float: right;
}

.shipping-details ul {
  list-style: none;
  position: relative;
  margin-top: 25px;
  padding-left: 26px;
}
.shipping-details ul:before {
  content: "";
  width: 5px;
  position: absolute;
  left: 0;
  top: 2px;
  bottom: 0;
  border-radius: 10px;
  background-color: #d9d9d9;
}

.shipping-details ul li .general-text {
  margin-bottom: 50px;
}
.shipping-details ul li {
  position: relative;
}
.shipping-details ul li::before {
  width: 18px;
  height: 18px;
  border-radius: 50px;
  border: solid 3px #1c3e92;
  background-color: #fff;
  position: absolute;
  left: -32px;
  top: 2px;
  content: "";
  z-index: 2;
}
.shipping-details ul li.active::before {
  background-color: #1c3e92;
}
.shipping-details ul li.inactive::before {
  border: solid 3px grey;
  background-color: grey;
}

.shipping-details ul li:last-child::after {
  position: absolute;
  left: -26px;
  top: 2px;
  bottom: 0;
  border-radius: 10px;
  background-color: #fff;
  content: "";
  width: 5px;
  z-index: 1;
}

img.product-img {
  position: absolute;
  bottom: -50px;

  right: -34%;
  height: 260px;
  width: 260px;
  object-fit: contain;
}
img.product-img.from-top {
  top: 107px;
  bottom: auto;
}
.order-details p,
.main-head,
.order-details h6 {
  max-width: 332px;
  word-break: break-word;
}
p.general-text.mb-0 {
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
}

.tracking-details .btn-secondary:hover {
  background-color: #565e64;
  border-color: #565e64;
  color: white;
}
a.general-text.d-inline-block {
  text-align: left;
  color: #f4ad3d;
  color: #1c3e92;
  text-decoration: none;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

div.blue-bg-form .logo {
  margin-bottom: 0px;
}

.h-440px {
  height: 440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: transparent;
}
form.white-bg-form.h-440px a {
  color: #f68d2c;
  text-align: right;
  margin-top: 2px;
}
.white-screen {
  position: fixed;
  background: white;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999999;
}
.btn-close:focus {
  box-shadow: none;
  outline: none;
}
/* admin css here  */
header {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15),
    inset 0 -1px 0 rgba(0, 0, 0, 0.15);
}
header .container {
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
header .logo {
  margin: 0;
  width: 150px;
  margin-left: -233px;
}
header .logo svg {
  margin-top: -25px;
  margin-bottom: -25px;
}
header button.btn.p-0.m-0 {
  outline: none;
  box-shadow: none;
  border: 0;
}
header button.btn.p-0.m-0 svg {
  width: 25px;
  height: 25px;
}
header .container .btn-secondary,
a.btn.btn-secondary.float-end.btn-sm.mt-2:hover,
.single-admin .btn-danger.show,
.single-admin .btn-danger:active,
.input-field-box button.btn.btn-secondary:active {
  background: #f68d2c;
  border-color: #f68d2c;
}
header .container .btn-secondary.show,
a.btn.btn-secondary.float-end.btn-sm.mt-2,
header .container .btn-secondary:first-child:active,
.input-field-box button.btn.btn-secondary,
header .container .btn-secondary:hover,
header .container .btn-secondary:focus,
header .container .btn-secondary.active,
caption .btn-check:checked + .btn {
  background: #193e8f;
  border-color: #193e8f;
  color: white;
}

.input-field-box .form-select,
.input-field-box .form-control,
.input-field-box .btn,
.input-field-box label {
  font-size: 14px;
}

.input-field-box .form-select:focus,
.input-field-box .form-control:focus,
.input-field-box
  .select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border-color: #193e8f;
  box-shadow: none;
  outline: none;
}

.top-space {
  margin-top: 50px;
}

.single-admin {
  background-color: #fff;
  color: #2c2c2c;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: normal;
  margin: 0;
  padding: 0;
}

.single-admin h4.inner-heading {
  font-size: 16px;
  font-weight: 700;
}
.single-admin .btn-danger {
  font-size: 14px;
  background-color: #193e8f;
  border-color: #193e8f;
  box-shadow: none;
  color: #fff;
  border-radius: 4px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.single-admin .drop_box {
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
  padding: 15px;
  position: relative;
}
.single-admin .drop_box .file-icon {
  width: 32px;
}
.single-admin .drop_box p {
  color: #a8a8a8;
  font-size: 12px;
  margin: 9px auto;
  text-align: center;
}
.single-admin .drop_box input {
  bottom: 0;
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.admin-sidebar .logo {
  margin: 0;
  width: 200px;
}
.admin-sidebar .logo svg {
  margin-top: -25px;
  margin-bottom: -25px;
}
.admin-sidebar .btn-close {
  padding: 0;
  margin: 0;
  border: 0;
  box-shadow: none;
  outline: none;
  margin-left: auto;
}
.admin-sidebar .offcanvas-body {
  padding: 0;
}
.admin-sidebar .offcanvas-body a {
  color: #193e8f;
  padding: 10px 23px;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.3s;
}
.admin-sidebar .offcanvas-body a svg {
  margin-right: 5px;
}

.admin-sidebar .offcanvas-body a:hover,
.admin-sidebar .offcanvas-body a.active {
  background-color: #193e8f;
  color: #fff;
}
.progress-file {
  margin-top: 10px;
}

.progress-file h6 {
  color: #193e8f;
  font-weight: 700;
}

.progress-file p {
  font-size: 14px;
  margin-bottom: 3px;
}
.progress-file .done-perc {
  color: #f68d2c;
  margin-top: 5px;
}

.progress-file .progress-bar {
  background-color: #193e8f;
}
.progress-file .progress {
  height: 13px;
}

/* table design css  */
.overflow-control {
  width: 100%;
  overflow: auto;
}

.table-style-order {
  width: 99%;
  margin: 0 auto;
  border: solid 0 #e8e8e8;
  box-shadow: 0 0 20px 0 rgba(27, 62, 143, 0.06);
  border-radius: 10px;
  background-color: #fff;
  position: relative;
  margin-bottom: 15px;
}
.heading-table {
  font-size: 36px;
  font-weight: bold;
  letter-spacing: 1.5px;
  text-align: left;
  color: #193e8f;
  padding: 0;
  margin-bottom: 20px;
}
.heading-table span {
  color: #f68d2c;
}

.table-style-order thead {
  position: sticky;
  top: 0;
}
.table-style-order thead th {
  padding: 18px 5px 20px 5px;
  background-color: #23418d;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: #fff;
  text-transform: capitalize;
}
.table-style-order thead th:first-child {
  border-radius: 10px 0px 0px 0px;
}
.table-style-order thead th:last-child {
  border-radius: 0px 10px 0px 0px;
}
.table-style-order tbody tr {
  border-bottom: 1px solid #d9d9d9;
}

.table-style-order tbody tr td {
  font-size: 13px;
  font-weight: normal;
  text-align: left;
  color: #313131;
  padding: 20px 5px 20px 5px;
  line-height: normal;
}
.table-style-order tbody tr td ul {
  list-style: none;
  padding: 0;
  min-width: 215px;
  max-width: 215px;
  margin: 0;
}
.table-style-order tbody tr td ul li {
  line-height: 1.7;
  word-break: break-word;
}
.table-style-order tbody tr td ul li a {
  color: #f68d2c;
  text-decoration: none;
}
.table-style-order tbody tr td ul li strong {
  text-transform: capitalize;
}
.table-style-order .product-info {
  width: 190px;
}

.img-holder {
  min-width: 170px;
  width: 170px;
  height: 170px;
  padding: 6px 5px 7px 6px;
  border-radius: 2px;
  background-color: #eaf0ff;
  margin-bottom: 5px;
}
.img-holder img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
ul.pagination {
  justify-content: flex-end;
}
ul.pagination .page-link {
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-size: 16px;
  padding: 0;
  margin: 0 10px 0 10px !important;
  background: transparent;
  border: 0;
  color: #313131;
  box-shadow: none;
}
ul.pagination .page-link[aria-label="Go to first page"],
ul.pagination .page-link[aria-label="Go to previous page"],
ul.pagination .page-link[aria-label="Go to last page"],
ul.pagination .page-link[aria-label="Go to next page"] {
  background: #dde7ff;
  color: #1b3e8f;
  padding: 10px;
  font-size: 12px;
}
ul.pagination .page-link[aria-label="Go to previous page"] {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
ul.pagination .page-link[aria-label="Go to last page"] {
  margin: 0 !important;
}

ul.pagination .page-item.disabled .page-link {
  background: transparent;
  color: #888;
  margin-right: 0 !important;
}

ul.pagination .page-link[aria-label="Go to next page"] {
  margin: 0 5px 0 0px !important;
}
ul.pagination li.page-item.active .page-link {
  padding: 0 10px 0 10px !important;
  margin: 0 !important;
  color: white;
  background: #23418d;
}

.info-list-vender {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  margin-top: 12px;
  flex-wrap: wrap;
  margin-bottom: 0;
}

.info-list-vender li {
  /* min-width: 10%; */
}

.input-field-box .select2-container--default .select2-selection--single {
  height: 35px;
  border: var(--bs-border-width) solid var(--bs-border-color);
}
.input-field-box .select2-container--default .select2-selection--multiple {
  border: var(--bs-border-width) solid var(--bs-border-color);
}
/* .input-field-box
    span.select2.select2-container.select2-container--default.select2-container--above.select2-container--open
    .select2-selection--single,
  .input-field-box
    span.select2.select2-container.select2-container--default.select2-container--below.select2-container--open
    .select2-selection--single {
    border-color: #193e8f;
  } */
.input-field-box
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 32px;
  font-size: 14px;
}
.input-field-box
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 34px;
}
.select2-container--default .select2-search__field {
  outline: none !important;
  border-color: #193e8f !important;
}
.custom-open-model {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 3%);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: right;
  z-index: 9;
}
.custom-open-model .inside-model {
  height: auto;
  background-color: #fff;
  width: 400px;
  padding: 20px;
  border: 1px solid #193e8f;
  position: relative;
}
.custom-open-model .inside-model .input-field-box.mb-0.w-100 {
  text-align: left;
}

.close-opned {
  position: absolute;
  background: #193e8f;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  top: -11px;
  right: -8px;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.unsubscribe-box {
  font-size: 18px;
}
.unsubscribe-box::before {
  position: absolute;
  left: -20px;
  content: "";
  width: 1px;
  background-color: #c1c1c1;
  height: 159px;
  margin: auto;
}
.unsubscribe-box .btn-danger {
  background: #193e8f;
  border-color: #193e8f;
}
.unsubscribe-box .btn-danger:hover,
.unsubscribe-box .btn-danger:focus,
.unsubscribe-box .btn-danger:active {
  background: #f68d2c;
  border-color: #f68d2c;
}

.success-unsub {
  background: #19875426;
  color: #198754;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.unsubscribe-box .sub-head {
  color: #198754;
  margin-top: 10px;
  margin-bottom: 2px;
  text-align: center;
}

.success-unsub svg {
  width: 30px;
  height: 30px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .tracking-details {
    width: 970px;
    height: 603px;
    padding: 44px 55px 25px 61px;
  }
}
@media (min-width: 320px) and (max-width: 991px) {
  .tracker-forms {
    width: 80%;
    height: auto;
  }
  .tracking-details {
    width: 80%;
    height: auto;
    padding: 40px;
  }
  .blue-bg-form {
    height: auto;
    max-width: 100%;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    padding-bottom: 30px;
  }
  .h-440px {
    height: auto;
  }
  .white-bg-form {
    width: 100%;
    padding: 68px 59px 59px 62px;
  }
  .or-line::after,
  .or-line:before {
    width: 47%;
  }
  .or-line span {
    width: max-content;

    padding: 0px 5px;
    background: #1c3e92;
    position: relative;
    z-index: 11;
  }
  .order-details,
  .shipping-details {
    width: 100%;
    float: none;
  }
  img.product-img {
    position: relative;
    top: 0;
    bottom: 0;
    right: 0;
    height: 260px;
    width: 260px;
    object-fit: contain;
    margin: 0 auto;
    display: block;
    margin-bottom: 25px;
  }

  .order-details p,
  .main-head,
  .order-details h6 {
    max-width: 100%;
  }
  .info-list-vender li {
    min-width: 50%;
  }
  header .logo {
    margin-left: 0;
  }
  .unsubscribe-box::before {
    content: none;
  }
}
@media (min-width: 320px) and (max-width: 574px) {
  .blue-bg-form {
    padding: 32px 30px 30px 30px;
  }
  .white-bg-form {
    padding: 68px 30px 59px 30px;
  }
  .blue-bg-form h3 {
    font-size: 26px;
  }
  .number-search-field .form-control,
  .btn.btn-primary {
    height: 45px;
  }
  .number-search-field .form-label {
    font-size: 14px;
  }
  .tracking-details .btn-secondary {
    font-size: 16px;
  }
  .link-copy {
    padding: 15px;
    display: block;
  }
  .link-texts {
    margin-left: 0;
    margin-top: 10px;
    text-align: center;
    font-size: 16px;
  }
  .link-texts a {
    text-align: center;
    font-size: 14px;
  }
  .general-text {
    font-size: 14px;
  }
  .sub-head {
    font-size: 20px;
  }
  .link-copy svg {
    margin: 0 auto;
    display: block;
  }
  .tracking-details {
    width: 90%;
    padding: 20px;
  }
  .tracker-forms {
    width: 90%;
  }
  .logo {
    max-width: 240px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .tracker-app {
    padding: 20px 0px;
  }
  header .logo {
    margin-left: 0;
  }
}
